import {
  DatePicker,
  FormLayout,
  FormRow,
  Input,
  Typography,
} from '@breeze-ai/ui-library';
import { upperCase } from 'lodash';
import moment from 'moment/moment';
import invariant from 'tiny-invariant';

import { type PortType } from '../../../../../model/Port';
import {
  type ConveyanceType,
  isContainerModeEnum,
} from '../../../../../model/Shipment';
import { IncotermSelector } from '../../../../components/IncotermSelector';
import { PlaceAutocomplete } from '../../../../components/PlaceAutocomplete/PlaceAutocomplete';
import { PortAutocomplete } from '../../../../components/PortAutocomplete/PortAutocomplete';
import {
  ETD_MIN_DAYS_OFFSET_BREEZE,
  LOCATION_TYPES_BY_CONVEYANCE,
} from '../../../../constants';
import {
  useDTCFlowContext,
  useDTCForms,
  useDTCStateAction,
} from '../../../context/hooks';
import { ContainerModeSelector } from '../../../selectors-to-deprecate/ContainerModeSelector';
import { ConveyanceTypeSelector } from '../../../selectors-to-deprecate/ConveyanceTypeSelector/ConveyanceTypeSelector';
import sharedStyles from '../../../shared/dtc-styles.module.scss';
import { useDTCInputValidation } from '../../../shared/forms-validations';

export const ShipmentDetailsForm = () => {
  const { configuration } = useDTCFlowContext();
  const { data: formsData, errors } = useDTCForms();
  const { setFormData, setFormError, resetFormFields } = useDTCStateAction();
  const validateInput = useDTCInputValidation();

  const selectablePortType: PortType =
    formsData.primaryTransportMode === 'sea' ? 'marine' : 'air';
  const shipmentLocationType =
    LOCATION_TYPES_BY_CONVEYANCE[formsData.primaryTransportMode];

  if (!configuration) {
    return null;
  }

  const { incoterms, container_modes } = configuration;

  return (
    <div className={sharedStyles.sectionWrapper}>
      <Typography level="h4">Shipment Details</Typography>
      <hr />
      <FormLayout>
        <FormRow className={sharedStyles.formRow}>
          <ConveyanceTypeSelector
            label="Primary Mode of Transport"
            type="buttons"
            required
            selected={formsData.primaryTransportMode}
            onChange={(conveyance) => {
              // Set container mode to first option when changing conveyance
              const containerMode = container_modes[conveyance][0].title;
              isContainerModeEnum(containerMode) &&
                setFormData({
                  primaryTransportMode: conveyance as ConveyanceType,
                  containerModeId: 1,
                  containerMode,
                });
              resetFormFields([
                'originPort',
                'destinationPort',
                'originPlace',
                'destinationPlace',
              ]);
            }}
          />
        </FormRow>
        <FormRow className={sharedStyles.formRow}>
          {shipmentLocationType === 'place' ? (
            <>
              <PlaceAutocomplete
                authApi={false}
                value={formsData?.originPlace}
                onChange={(originPlace) => {
                  validateInput('originPlace', originPlace.provider_place_uuid);
                  setFormData({ originPlace });
                }}
                getOptionDisabled={({ provider_place_uuid }) =>
                  provider_place_uuid ===
                  formsData?.destinationPlace?.provider_place_uuid
                }
                showPlacesAttribution={true}
                inputProps={{
                  label: 'Origin',
                  placeholder: 'Origin address',
                  testId: 'location-selector-origin',
                  error: !!errors?.originPlace,
                  errorHelperText: errors?.originPlace?.reason,
                  validator: (value) => validateInput('originPlace', value),
                  onError: () => setFormData({ originPlace: undefined }),
                }}
              />
              <PlaceAutocomplete
                authApi={false}
                value={formsData?.destinationPlace}
                onChange={(destinationPlace) => {
                  validateInput(
                    'destinationPlace',
                    destinationPlace.provider_place_uuid,
                  );
                  setFormData({ destinationPlace });
                }}
                getOptionDisabled={({ provider_place_uuid }) =>
                  provider_place_uuid ===
                  formsData?.originPlace?.provider_place_uuid
                }
                showPlacesAttribution={true}
                inputProps={{
                  label: 'Destination',
                  placeholder: 'Destination address',
                  testId: 'location-selector-destination',
                  error: !!errors?.destinationPlace,
                  errorHelperText: errors?.destinationPlace?.reason,
                  validator: (value) =>
                    validateInput('destinationPlace', value),
                  onError: () => setFormData({ destinationPlace: undefined }),
                }}
              />
            </>
          ) : (
            <>
              <PortAutocomplete
                authApi={false}
                type={selectablePortType}
                value={formsData?.originPort}
                onChange={(originPort) => {
                  validateInput('originPort', originPort?.code);
                  setFormData({ originPort });
                }}
                getOptionDisabled={({ id }) =>
                  id === formsData?.destinationPort?.id
                }
                inputProps={{
                  label: 'Origin',
                  placeholder:
                    selectablePortType === 'marine'
                      ? 'Origin port'
                      : 'Origin airport',
                  testId: 'location-selector-origin',
                  error: !!errors?.originPort,
                  errorHelperText: errors?.originPort?.reason,
                  validator: (value) => validateInput('originPort', value),
                  onError: () => setFormData({ originPort: undefined }),
                }}
              />
              <PortAutocomplete
                authApi={false}
                type={selectablePortType}
                value={formsData?.destinationPort}
                onChange={(destinationPort) => {
                  validateInput('destinationPort', destinationPort?.code);
                  setFormData({ destinationPort });
                }}
                getOptionDisabled={({ id }) => id === formsData?.originPort?.id}
                inputProps={{
                  label: 'Destination',
                  placeholder:
                    selectablePortType === 'marine'
                      ? 'Destination port'
                      : 'Destination airport',
                  testId: 'location-selector-destination',
                  error: !!errors?.destinationPort,
                  errorHelperText: errors?.destinationPort?.reason,
                  validator: (value) => validateInput('destinationPort', value),
                  onError: () => setFormData({ destinationPort: undefined }),
                }}
              />
            </>
          )}
          <ContainerModeSelector
            options={container_modes[formsData.primaryTransportMode]}
            selectedContainerModeId={formsData?.containerModeId}
            onChange={(containerModeId, containerMode) => {
              validateInput('containerModeId', `${containerModeId}`);
              const containerModeEnum = upperCase(containerMode);
              invariant(
                isContainerModeEnum(containerModeEnum),
                `Invalid load type: ${containerModeEnum}`,
              );
              setFormData({
                containerModeId,
                containerMode: containerModeEnum,
              });
            }}
            inputProps={{
              required: true,
              error: !!errors?.containerModeId,
              errorHelperText: errors?.containerModeId?.reason,
              validator: (value) => validateInput('containerModeId', value),
            }}
          />
          <DatePicker
            value={formsData?.etd}
            onChange={(etd) => setFormData({ etd: etd ?? undefined })}
            position="bottom-end"
            required
            minDate={moment()
              .startOf('day')
              .subtract(ETD_MIN_DAYS_OFFSET_BREEZE, 'days')}
            onValidation={(error, reason) => {
              setFormError({ field: 'etd', error, reason });
            }}
            inputProps={{
              label: 'Departure Date',
              placeholder: 'MM/DD/YYYY',
              error: !!errors?.etd,
              errorHelperText: errors?.etd?.reason,
              testId: 'dtc-etd-date-picker',
            }}
          />
          <DatePicker
            value={formsData?.eta}
            onChange={(eta) => setFormData({ eta: eta ?? undefined })}
            position="bottom-end"
            required
            minDate={
              formsData?.etd ? moment(formsData?.etd).startOf('day') : undefined
            }
            onValidation={(error, reason) =>
              setFormError({ field: 'eta', error, reason })
            }
            inputProps={{
              label: 'Arrival Date',
              placeholder: 'MM/DD/YYYY',
              error: !!errors?.eta,
              errorHelperText: errors?.eta?.reason,
              testId: 'dtc-eta-date-picker',
            }}
          />
          <IncotermSelector
            options={incoterms}
            selectedIncoterm={formsData?.incoterm}
            onChange={(incoterm) => setFormData({ incoterm })}
          />
          <ConveyanceTypeSelector
            type="select"
            label="Secondary Mode of Transport"
            selected={formsData?.secondaryTransportMode}
            onChange={(secondaryTransportMode) =>
              setFormData({ secondaryTransportMode })
            }
            testId="dtc-secondary-conveyance-type-selector"
          />
          <Input
            label="Container / Trailer / AWB Number(s)"
            placeholder="Number1, number2,..."
            helperText="Insert comma-separated numbers"
            value={formsData?.containerIds ?? ''}
            onChange={({ target: { value } }) =>
              setFormData({ containerIds: value })
            }
            testId="dtc-container-ids-input"
            error={!!errors?.containerIds}
            errorHelperText={errors?.containerIds?.reason}
            validator={(value) => validateInput('containerIds', value)}
          />
          <Input
            value={formsData?.vehicleName ?? ''}
            label="Vessel Name / Flight Number"
            placeholder="Enter name"
            testId="dtc-vehicle-name-input"
            onChange={({ target: { value } }) =>
              setFormData({ vehicleName: value })
            }
          />
        </FormRow>
      </FormLayout>
    </div>
  );
};
