import { type ComponentProps, type ReactNode } from 'react';
import { Autocomplete } from '@breeze-ai/ui-library';
import { sortBy } from 'lodash';

import { type TitledOption } from '../../../model/TitledOption';

type CommodityTypeSelectorProps = Omit<
  ComponentProps<typeof Autocomplete<number>>,
  'getOptionValue' | 'options' | 'onChange' | 'onBlur'
> & {
  options: TitledOption[];
  onChange: (category: string, categoryId: number) => void;
  onBlur?: (value: string) => void;
  error?: boolean;
  helperText?: ReactNode;
  required?: boolean;
};

export const CommodityTypeSelector = ({
  value,
  options,
  onChange,
  onBlur,
  error,
  helperText,
  required = true,
  inputProps,
  ...props
}: CommodityTypeSelectorProps) => {
  const ids = sortBy(options, 'title').map(({ id }) => id);

  const handleChange = (categoryId: number) => {
    const { title } = options.find(
      ({ id }) => id === categoryId,
    ) as TitledOption;

    onChange(title, categoryId);
    onBlur?.(`${categoryId}`);
  };

  return (
    <Autocomplete<number>
      options={ids}
      getOptionValue={(option) => option}
      value={value}
      onChange={(categoryId) => handleChange(categoryId)}
      disableClearable={true}
      getOptionLabel={(categoryId) =>
        options.find(({ id }) => id === categoryId)?.title ?? ''
      }
      data-testid="commodity-category-selector"
      inputProps={{
        required,
        label: 'Commodity Category',
        placeholder: 'Select a commodity type',
        error,
        errorHelperText: helperText,
        onBlur: ({ target: { value } }) => onBlur?.(value),
        ...inputProps,
      }}
      {...props}
    />
  );
};
