import { FormLayout, FormRow, Input, Typography } from '@breeze-ai/ui-library';
import { type IconName } from '@fortawesome/fontawesome-svg-core';

import { ShipmentSpecialConditionsSelector } from '../../../../components/ShipmentSpecialConditionsSelector/ShipmentSpecialConditionsSelector';
import {
  useDTCFlowContext,
  useDTCForms,
  useDTCStateAction,
} from '../../../context/hooks';
import { CommodityTypeSelector } from '../../../selectors-to-deprecate/CommodityTypeSelector';
import { CommodityValueSelector } from '../../../selectors-to-deprecate/CommodityValueSelector';
import sharedStyles from '../../../shared/dtc-styles.module.scss';
import { useDTCInputValidation } from '../../../shared/forms-validations';
import styles from './CargoDetailsForm.module.scss';

export const CargoDetailsForm = () => {
  const { configuration } = useDTCFlowContext();
  const { data: formsData, errors } = useDTCForms();
  const { setFormData } = useDTCStateAction();
  const validateInput = useDTCInputValidation();

  if (!configuration) {
    return null;
  }

  const { commodities, currencies, special_conditions } = configuration;
  return (
    <div className={sharedStyles.sectionWrapper}>
      <Typography level="h4">Cargo Details</Typography>
      <hr />
      <FormLayout>
        <FormRow className={sharedStyles.formRow}>
          <Input
            label="Commodity Description"
            placeholder="Describe the commodity"
            testId="dtc-commodity-description-input"
            required
            value={formsData?.commodityDescription}
            onChange={({ target: { value } }) =>
              setFormData({ commodityDescription: value })
            }
            validator={(value) => validateInput('commodityDescription', value)}
            error={!!errors?.commodityDescription}
            errorHelperText={errors?.commodityDescription?.reason}
          />
          <CommodityTypeSelector
            options={commodities}
            value={formsData?.commodityCategoryId}
            onChange={(commodityCategory, commodityCategoryId) =>
              setFormData({ commodityCategory, commodityCategoryId })
            }
            onBlur={(value) => {
              const valid = validateInput('commodityCategoryId', value);
              if (!valid) {
                setFormData({
                  commodityCategoryId: undefined,
                  commodityCategory: undefined,
                });
              }
            }}
            error={!!errors?.commodityCategoryId}
            helperText={errors?.commodityCategoryId?.reason}
          />
          <CommodityValueSelector
            currencyOptions={currencies}
            currency={formsData?.currency}
            value={formsData?.commodityValue}
            onChange={(commodityValue) => setFormData({ commodityValue })}
            onCurrencyChange={(currency) => setFormData({ currency })}
            validator={(value) => validateInput('commodityValue', value)}
            error={!!errors?.commodityValue}
            errorHelperText={errors?.commodityValue?.reason}
          />
          <Input
            type="number"
            label="Freight Cost"
            placeholder="Enter amount"
            testId="dtc-freight-cost-input"
            prefixIcon={formsData?.currency?.toLowerCase() as IconName}
            min={0}
            value={formsData?.freightCost ?? ''}
            onChange={({ target: { value } }, cost) =>
              setFormData({
                freightCost: value ? (cost as number) : undefined,
              })
            }
            validator={(value) => validateInput('freightCost', value)}
            error={!!errors?.freightCost}
            errorHelperText={errors?.freightCost?.reason}
          />
        </FormRow>
        <FormRow className={styles.specialConditions}>
          <Typography level="h5">Special Conditions</Typography>
        </FormRow>
        <FormRow>
          <ShipmentSpecialConditionsSelector
            options={special_conditions}
            selected={formsData?.specialConditions}
            onChange={(specialConditions) => setFormData({ specialConditions })}
          />
        </FormRow>
      </FormLayout>
    </div>
  );
};
