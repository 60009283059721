import { type Quote } from '../../../model/Quote';
import { LOCATION_TYPES_BY_CONVEYANCE } from '../../../ui/constants';
import { type DTCFlowTypes } from '../../../ui/dtc-flow/constants';
import { type DTCFormsData } from '../../../ui/dtc-flow/context/types';
import { toISODate } from '../../../utils/dates';
import { normalizeQuoteResponse } from '../quotes/normalizers';
import { type QuoteModel, QuoteStatusEnum } from '../quotes/types';
import {
  type DTCCustomerLeadPayload,
  type DTCCustomerPayload,
  type DTCQuoteModel,
  type DTCQuotePayload,
} from './types';

export const normalizeDTCQuoteResponse = (response: DTCQuoteModel): Quote => {
  const normalizedQuote = normalizeQuoteResponse(
    response as Omit<QuoteModel, 'status' | 'distributor'>,
  );

  normalizedQuote.status =
    response?.status === 'approved'
      ? QuoteStatusEnum.READY_FOR_POLICY
      : QuoteStatusEnum.PENDING_FOR_BREEZE_MANUAL_REVIEW;

  return normalizedQuote;
};

export const createDTCCustomerPayload = (
  flowType: DTCFlowTypes,
  formsData: Required<DTCFormsData>,
): DTCCustomerPayload => ({
  flow_type: flowType,
  company_name: formsData.companyName,
  company_address: formsData.companyAddress,
  contact_person: formsData.contactName,
  contact_email: formsData.contactEmail,
  contact_phone: formsData.contactPhone,
});

export const createDTCCustomerLeadPayload = (
  flowType: DTCFlowTypes,
  formsData: Required<DTCFormsData>,
): DTCCustomerLeadPayload => ({
  ...createDTCCustomerPayload(flowType, formsData),
  flow_type: flowType,
  dba: formsData.companyDBA,
  years_in_business: formsData.yearsInBusinessId,
  company_industry: formsData.companyIndustry,
  has_previously_carried_credit_insurance: formsData.previousCreditInsurance,
  // TODO: These fields are no longer used and should be removed
  state_of_incorporation: formsData.stateOfIncorporation?.abbreviation,
  government_tax_id: formsData.governmentTaxId,
});

export const createDTCQuotePayload = (
  customer_id: number,
  formsData: Required<DTCFormsData>,
): DTCQuotePayload => {
  const locationType =
    LOCATION_TYPES_BY_CONVEYANCE[formsData.primaryTransportMode];

  return {
    customer_id,
    primary_mode_of_transport: formsData.primaryTransportMode,
    secondary_mode_of_transport: formsData.secondaryTransportMode,
    origin_place: locationType === 'place' ? formsData.originPlace : undefined,
    destination_place:
      locationType === 'place' ? formsData.destinationPlace : undefined,
    origin_port_code: formsData.originPort?.code,
    destination_port_code: formsData.destinationPort?.code,
    commodity_name: formsData.commodityCategory,
    commodity_type_id: formsData.commodityCategoryId,
    commodity_description: formsData.commodityDescription,
    commodity_value: formsData.commodityValue,
    currency_code: formsData.currency,
    freight_cost: formsData.freightCost,
    etd: toISODate(formsData.etd),
    eta: toISODate(formsData.eta),
    container_ids: formsData.containerIds?.split(','),
    container_mode: formsData.containerMode,
    incoterm_code: formsData.incoterm,
    vessel_name: formsData.vehicleName,
    special_conditions: formsData.specialConditions?.map((value) => ({
      value,
    })),
  };
};
