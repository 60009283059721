import { type ComponentProps } from 'react';
import { Select, type SelectOptionType } from '@breeze-ai/ui-library';

import { type TitledOption } from '../../../model/TitledOption';
import { getFormattedContainerMode } from '../../../network/apis/quotes/helpers';

type ContainerModeSelectorProps = {
  options: TitledOption[];
  selectedContainerModeId?: number;
  onChange: (value: number, name?: string) => void;
  inputProps?: Partial<ComponentProps<typeof Select>>;
};

export const ContainerModeSelector = ({
  options,
  selectedContainerModeId,
  onChange,
  inputProps,
}: ContainerModeSelectorProps) => {
  const containerModes = options.map<SelectOptionType<number>>(
    ({ id, title }) => ({
      value: id,
      payload: id,
      label: getFormattedContainerMode(title),
      selected: id === selectedContainerModeId,
    }),
  );

  return (
    <Select<number>
      {...inputProps}
      disabled={options.length === 0}
      value={selectedContainerModeId}
      options={containerModes}
      onChange={({ payload, label }) => {
        label && onChange(payload, getFormattedContainerMode(label));
      }}
      required
      label="Load Type"
      placeholder="Select..."
      testId="container-mode-selector"
    />
  );
};
